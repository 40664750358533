import React from "react";
import ProgressBar from "./progress_bar";
import { sortImage, sortQuizzes } from "./sorting_table";

export default class SaqClinicalDiscipline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      filteredData: this.props.clinical_disciplines,
      sortCol: "number_of_items",
      sortDir: "asc",
    };
  }

  handleSort = (sortCol, sortDir) => {
    const isCertainty = sortCol === 'certainty_percentage';
    this.setState({
      sortCol,
      sortDir,
      filteredData: sortQuizzes(
        this.state.filteredData,
        sortCol,
        sortDir,
        false,
        isCertainty
      ),
    });
  };

  render() {
    return (
      <>
        <div className="performanceQuizCard focusArea">
          <div className="performanceQuizCardTitleRow filter-label-focus" tabIndex="0" onClick={() => {
                this.setState({ collapsed: !this.state.collapsed });
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.setState({ collapsed: !this.state.collapsed });
                }
              }}>
            <div
              className={
                this.state.collapsed
                  ? "saqResultsCardOpen"
                  : "saqResultsCardClosed"
              }
            >
            </div>
              <h2 className="saqResultsCardTitle1">CLINICAL DISCIPLINE</h2>
              <h6 className="saqResultsCardTitle2 d-none">Identify Focus Areas: Clinical Discipline</h6>
          </div>
          <table
            className="performanceQuizCardTable"
            style={{ display: this.state.collapsed ? "none" : "table" }}
          >
            <thead>
              <tr>
                <th width="25%">CLINICAL DISCIPLINE</th>
                <th width="32%">
                  QUESTIONS COMPLETED &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "completed_questions",
                    this.handleSort
                  )}
                </th>
                <th width="18%">
                  ACCURACY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "accuracy_percentage",
                    this.handleSort
                  )}
                </th>
                <th width="25%">
                  CERTAINTY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "certainty_percentage",
                    this.handleSort
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.clinical_disciplines.map(
                (clinical_discipline, index) => (
                  <tr key={index}>
                    <td width="25%">
                      {clinical_discipline.name}
                      <div className="d-none">
                        {clinical_discipline.completed_questions}/{clinical_discipline.total_questions} 
                        <span>questions</span>
                      </div>
                    </td>
                    <td width="30%">
                      {clinical_discipline.completed_questions}/
                      {clinical_discipline.total_questions}
                    </td>
                    <td width="20%">
                      {clinical_discipline.accuracy_percentage}%
                    </td>
                    <td width="25%">
                      <ProgressBar percent={clinical_discipline.certainty_percentage} />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
