import React from "react";
import ProgressBar from "./progress_bar";
import { sortImage, sortQuizzes } from "./sorting_table";

export default class SaqSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.isDefaultCollapse,
      filteredData: this.props.systems,
      sortCol: "number_of_items",
      sortDir: "asc",
    };
  }
  

  componentDidMount() {
    window.addEventListener("resize", this.updateCollapsedState);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCollapsedState);
  }

  updateCollapsedState = () => {
    const isSmallScreen = window.innerWidth < 768;
    this.setState({ collapsed: !isSmallScreen });
  };

  handleSort = (sortCol, sortDir) => {
    const isCertainty = sortCol === 'certainty_percentage';
    this.setState({
      sortCol,
      sortDir,
      filteredData: sortQuizzes(
        this.state.filteredData,
        sortCol,
        sortDir,
        false,
        isCertainty
      ),
    });
  };

  render() {
    return (
      <>
        <div className="performanceQuizCard focusArea">
          <div className="performanceQuizCardTitleRow filter-label-focus" tabIndex="0"
              onClick={() => {
                this.setState({ collapsed: !this.state.collapsed });
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.setState({ collapsed: !this.state.collapsed });
                }
              }}
            >
            <div
              className={
                this.state.collapsed
                  ? "saqResultsCardOpen"
                  : "saqResultsCardClosed"
              }
            ></div>
            <h2 className="saqResultsCardTitle1">SYSTEM</h2>
            <h6 className="saqResultsCardTitle2 d-none">Identify Focus Areas: System</h6>
          </div>
          <table
            className="performanceQuizCardTable"
            style={{ display: this.state.collapsed ? "none" : "table" }}
          >
            <thead>
              <tr>
                <th width="25%">SYSTEM</th>
                <th width="30%">
                  QUESTIONS COMPLETED &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "completed_questions",
                    this.handleSort
                  )}
                </th>
                <th width="20%">
                  ACCURACY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "accuracy_percentage",
                    this.handleSort
                  )}
                </th>
                <th width="25%">
                  CERTAINTY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "certainty_percentage",
                    this.handleSort
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.systems.map((system, index) => (
                <tr key={index}>
                  <td width="25%">
                    {system.name}
                    <div className="d-none">
                      {system.completed_questions}/{system.total_questions}
                      <span>questions</span>
                    </div>
                  </td>
                  <td width="30%">
                    {system.completed_questions}/{system.total_questions}
                  </td>
                  <td width="20%">{system.accuracy_percentage}%</td>
                  <td width="25%">
                    <ProgressBar percent={system.certainty_percentage} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
