import React from 'react';
import ProgressBar from './progress_bar';
import { sortImage, sortQuizzes } from './sorting_table';

export default class SaqPerformanceQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: this.props.quizzes,
      sortCol: 'number_of_items',
      sortDir: 'asc',
      isMobileView: window.innerWidth < 834,
      collapsed: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateViewState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewState);
  }

  updateViewState = () => {
    const isMobileView = window.innerWidth < 834;
    this.setState({ isMobileView });
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  handleSort = (sortCol, sortDir) => {
    const isDate = sortCol === 'completed_at';
    const isCertainty = sortCol === 'certainty_percentage';
    this.setState({
      sortCol,
      sortDir,
      filteredData: sortQuizzes(
        this.state.filteredData,
        sortCol,
        sortDir,
        isDate,
        isCertainty
      ),
    });
  };

  render() {
    const isScrollable = this.state.filteredData.length > 25;

    return (
      <div className="performanceQuizCardContainer">
        <div className={`performanceQuizCard performanceQuiz ${isScrollable ? 'scrollable-table-body' : ''}`}>
          <div
            className={`performanceQuizCardTitleRow ${this.state.isMobileView ? "filter-label-focus" : ""}`}
            tabIndex={this.state.isMobileView ? '0' : undefined}
            onClick={this.toggleCollapse}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.toggleCollapse();
              }
            }}

          >
            <div
              className={
                this.state.collapsed
                  ? 'saqResultsCardOpen'
                  : 'saqResultsCardClosed'
              }
            />
            <h6 className='performanceQuizCardTitle freeze_header'>Performance By Quiz</h6>
          </div>
          <div className="performanceQuizCardTableContainer">
            <table
              className='performanceQuizCardTable'
              style={{ display: this.state.collapsed ? 'none' : 'table' }}
            >
              <thead className="freeze_header">
                <tr>
                  <th width='15%'>QUIZ</th>
                  <th width='25%'>
                    # OF QUESTIONS &nbsp;
                    {sortImage(this.state.sortCol, 'number_of_items', this.handleSort)}
                  </th>
                  <th width='15%'>
                    DATE &nbsp;
                    {sortImage(this.state.sortCol, 'completed_at', this.handleSort)}
                  </th>
                  <th width='20%'>
                    ACCURACY &nbsp;
                    {sortImage(this.state.sortCol, 'accuracy_percentage', this.handleSort)}
                  </th>
                  <th width='25%'>
                    CERTAINTY &nbsp;
                    {sortImage(this.state.sortCol, 'certainty_percentage', this.handleSort)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredData.map((quiz, index) => (
                  <tr key={index}>
                    <td width='15%'>
                      <a
                        href={quiz.quiz_url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {quiz.name}
                      </a>
                      <div className='d-none'>
                        {quiz.number_of_items || 0} <span>questions</span>
                      </div>
                    </td>
                    <td width='25%'>{quiz.number_of_items || 0}</td>
                      {
                        quiz.completed_at ? (
                          <>
                            <td width='15%'>{quiz.completed_at}</td>
                            <td width='20%'>{quiz.accuracy_percentage}%</td>
                            <td width='25%'><ProgressBar percent={quiz.certainty_percentage} /></td>
                          </>
                        ) : (
                          <>
                            <td width='15%'>
                              <a
                                data-action="click->content-library-modal#openQuizModal"
                                data-quiz-id={quiz.id}
                                data-quiz-name={quiz.name}
                                data-document-title={quiz.name}
                                data-questions-answered={`${quiz.questions_completed}/${quiz.number_of_items}`}
                                data-percentage-correct={quiz.accuracy_percentage}
                                data-source="TO-DO"
                                href="#"
                              >
                                In progress
                              </a>
                            </td>
                            <td width='20%'></td>
                            <td width='25%'></td>
                          </>
                        )
                      }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
