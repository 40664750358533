import React, { useState, useEffect } from "react"
import axios from 'axios'
import FilterableList from "Gen/FilterableList/component"
import Alert from "Gen/Alert/component"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import MenuOpenIcon from "images/menu_open_icon_blue.svg";

const LeadCourseAdminForm = ({ courses, choices, max, endpoint, currentUserEmail, caRoleName, defaultCount }) => {
  const [current, setCurrent] = useState(courses)
  const [errors, setErrors] = useState([])
  const [removed, setRemoved] = useState([])
  const [added, setAdded] = useState([])
  const [isDirty, setIsDirty] = useState(false);
  const [showLcaForm, setShowLcaForm] = useState(max == defaultCount);

  const numOfRows = courses.length
  const columns = 4 
  const maxRowsPerCourse = Math.ceil(max / 3)

  const selfAssignmentWarning = <p>By actively assigning yourself to the role of LCA, your current permissions will be restricted.<br/><br/> To maintain your current permissions, click cancel</p>
  const caAssignmentWarning = <p>By actively reassigning the Curriculum Administrator to the role of LCA, their current permissions will be restricted.<br/><br/> To maintain their current permissions, click cancel</p>

  const saveChanges = async () => {
    try {
      const response = await axios({
        url: endpoint,
        method: 'put',
        data: { lcas: current },
        withCredentials: true,
        headers: ReactOnRails.authenticityHeaders({
          'Accept': 'application/json'
        })
      });

      console.log(response.data);

      if (response.data.errors.length) {
        setErrors([...response.data.errors.map((e, i) => ({ id: i, error: e }))]);
      }
      if (response.data.removed.length) {
        setRemoved([...response.data.removed.map((r, i) => ({ id: i, removed: r }))]);
      }
      if (response.data.added.length) {
        setAdded([...response.data.added.map((a, i) => ({ id: i, added: a }))]);
      }

      setIsDirty(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmation = (choice, onConfirmation) => {
    if(choice.role == caRoleName) {
      const message = choice.email == currentUserEmail ? selfAssignmentWarning : caAssignmentWarning

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='warning-popup'>
              <p>{message}</p>
              <button className='outline-button' onClick={() => {
                onConfirmation(choice, false)
                onClose()
              }}>Cancel</button>
              <button className='primary-button' onClick={() => {
                onConfirmation(choice, true)
                setIsDirty(true);
                onClose()
                }} >Continue</button>
            </div>
          );
        }
      });
    } else {
      onConfirmation(choice, true)
      setIsDirty(true);
    }
  }

  const handleSelect = (choice, course) => {
    let isOk = false

    setCurrent((prev) => {
      return prev.map((c) => {
        if (c.document_set.id === course.documentSet.id) {
          if (c.lcas.indexOf(choice) === -1) {
            isOk = true
            return { document_set: course.documentSet, lcas: [choice, ...c.lcas] }
          }
        }
        return c
      })
    })
    return isOk
  }

  const handleRemove = (item, course) => {
    setCurrent((prev) => {
      return prev.map((c) => {
        const index = c.lcas.indexOf(item)
        if (c.document_set.id === course.documentSet.id && index !== -1) {
          c.lcas.splice(index, 1)
          setIsDirty(true);
          return { document_set: course.documentSet, lcas: c.lcas }
        } else {
          return c
        }
      })
    })
  }

  const clearAlert = (type, id) => {
    switch (type) {
      case "error":
        setErrors([...errors.filter((e)=>e.id !== id)])
        break;
      case "notice":
        setRemoved([...removed.filter((r)=>r.id !== id)])
        break;
      case "success":
        setAdded([...added.filter((a)=>a.id !== id)])
        break;
    }
  }

  const getAlerts = () => {
    return [...getErrors(),...getRemoved(),...getAdded()]
  }

  const getErrors = () => {
    return errors.map((e) => {
      return <Alert type="error" typeId={e.id} message={e.error} dismissCallback={clearAlert} />
    })
  }

  const getRemoved = () => {
    return removed.map((r) => {
      const msg = `Successfully removed LCA, ${r.removed.lca}, from ${r.removed.document_set}.`
      return <Alert type="notice" typeId={r.id} message={msg} dismissCallback={clearAlert} />
    })
  }

  const getAdded = () => {
    return added.map((a) => {
      const msg = `Successfully added LCA, ${a.added.lca}, to ${a.added.document_set}.`
      return <Alert type="success" typeId={a.id} message={msg} dismissCallback={clearAlert} />
    })
  }

  const getHeader = () => {
    const heads = []
    for (let i = 2; i <= columns; i++) {
      heads.push(<div className={`head row-1 col-${i}`}>{"Lead Course Administrator"}</div>)
    }
    return heads
  }

  const getRows = () => {
    const rows = []
    let row = 2
    let dark = true

    for (const [_, course] of current.entries()) {
      let lcas = course.lcas
      let lcaIndex = 0
      let additionalClasses = ""

      if(dark) {
        additionalClasses = " darker"
      }

      dark = !dark

      for (let k = 0; k < maxRowsPerCourse; k++) {
        let classes = `row-${row} ${additionalClasses}`

        if(k == 0) {
          rows.push(<div className={`${classes} col-1`}>{course.document_set.name}</div>)
        } else {
          rows.push(<div className={`${classes} col-1`}></div>)
        }

        for (let j = 2; j <= columns; j++) {
          let element = null

          if(lcaIndex < max) {
            element = <FilterableList
              initial={lcas[lcaIndex] || null}
              items={choices.filter(c => !lcas.map(lca=>lca.email).includes(c.email) )}
              filterChecks={["name", "email"]}
              staticData={{documentSet: course.document_set}}
              selectCallback={handleSelect}
              removeCallback={handleRemove}
              confirmCallback={handleConfirmation}
            />
          }
          
          rows.push(<div className={`${classes} col-${j}`}>{element}</div>)
          lcaIndex++
        }

        row++
      }
    }
    return rows
  }

  useEffect(() => {
    // Autosave when the user makes a selection
    if (isDirty) {
      saveChanges(); // Call the save function whenever isDirty changes
    }
  }, [isDirty]);

  return(
    <div className="panel">
      <div className="alert-container">
        {getAlerts()}
      </div>
      <h1 className={`lca-icon ${showLcaForm ? 'lca-heading' : ''}`} onClick={ ()=> setShowLcaForm(!showLcaForm) }>Lead Course Administrators <img class={`lca-icon ${showLcaForm ? 'expanded' : ''}`} src={MenuOpenIcon}/> </h1>
      <div class='lca-form' hidden={!showLcaForm}>
        <div className='panel-grid-1x4'>
        <div className="head row-1 col-1">Course</div>
          {getHeader()}
          {getRows()}
        </div>
      </div>
    </div>
  )
}

export default props => <LeadCourseAdminForm {...props} />
