import React from 'react';

import TeachingPointModal from 'NewEditDoc/TeachingPointModal/component'

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";
import analytics from '../../../../../application/analytics';

class StudentResultsBonusDetailsTable extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      search: '',
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      accuracy: '',
      certainty: '',
      system: '',
      clinical_focus: '',
      clinical_learning_calibration: '',
    };
  }

  sortDeliveries = (sortCol, sortDir) =>{
    var data = this.state.filteredData
    data.sort((a, b) => {
      let fa = a[sortCol].toLowerCase(),
          fb = b[sortCol].toLowerCase();
      if (fa < fb) {
          return sortDir == 'asc' ? -1 : 1;
      }
      if (fa > fb) {
        return sortDir == 'asc' ? 1 : -11;
      }
      return 0;
    });
    return data
  }

  sortImage = (sortCol)=>{
    return this.state.sortCol == sortCol ? this.state.sortDir == 'asc' ? TableDownArrowImage : TableUpArrowImage : TableDownArrowImage
  }

  getTeachingPointModal(row) {
    if (row.teaching_point_url_name != 'N/A') {
      return <TeachingPointModal
        title={row.teaching_point_url_name}
        docType="ReusableObject"
        documentId={row.teaching_point_id}
        versionId={row.teaching_point_publish_variant_id}
        currentUser={this.props.user}
        isAdmin={this.props.admin}
        currentRoleName="Student"
        permissions={{}}
        itemResponseId={row.item_response_id}
      />
    }
  }

  trackFilterUsage(filter) {
    analytics.track("use_filters_on_tab_3", { page: window.location.href, source: 'Calibrate Reports', filters: filter })
  }

  render() {
    return <>
      <div className="detailsSection">
        <div className="col-md-12" style={{display: 'flex', flexDirection: 'column'}}>
          <div className="filtersRow" style={{borderBottom: '0px', height: 'auto'}}>
            <div className="filtersContainer">
              <div className='col-md-10'>
                <select className='form-control filterSelect' value={this.state.system || 'System'} defaultValue={this.state.system || "System"} style={{marginRight: '0px'}} onChange={(val) => {
                  this.trackFilterUsage({ system: val.target.value })
                  this.setState({
                    system: val.target.value,
                    filteredData: this.props.rows.filter((item) => item.system?.indexOf(val.target.value) > -1 &&
                      item.clinical_focus?.indexOf(this.state.clinical_focus) > -1 &&
                      item.certainty?.indexOf(this.state.certainty) > -1 &&
                      item.accuracy?.indexOf(this.state.accuracy) > -1 &&
                      ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                    ),
                  })}}>
                  <option value="">System</option>
                  {this.props.system_options.map(function(option){
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>

                <select className= 'form-control filterSelect' value={this.state.clinical_focus || "Clinical Focus"} style={{marginRight: '0px'}} onChange={(val) => {
                  this.trackFilterUsage({ clinical_focus: val.target.value })
                  this.setState({
                    clinical_focus: val.target.value,
                    filteredData: this.props.rows.filter((item) => item.clinical_focus?.indexOf(val.target.value) > -1 &&
                      item.certainty?.indexOf(this.state.certainty) > -1 &&
                      item.system?.indexOf(this.state.system) > -1 &&
                      item.accuracy?.indexOf(this.state.accuracy) > -1 &&
                      ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                    ),
                  })}}>
                  <option value="">Clinical Focus</option>
                  {this.props.clinical_focus_options.map(function(option){
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>

                <select className= 'form-control filterSelect' value={this.state.accuracy || "Accuracy"} style={{marginRight: '0px'}} onChange={(val) => {
                    this.trackFilterUsage({ accuracy: val.target.value })
                    this.setState({
                      accuracy: val.target.value,
                      filteredData: this.props.rows.filter((item) => item.accuracy?.indexOf(val.target.value) > -1 &&
                        item.clinical_focus?.indexOf(this.state.clinical_focus) > -1 &&
                        item.system?.indexOf(this.state.system) > -1 &&
                        item.certainty?.indexOf(this.state.certainty) > -1 &&
                        ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)

                      ),
                    })
                  }}>
                  <option value="">Accuracy</option>
                  {this.props.accuracy_options.map(function(option){
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>

                <select className= 'form-control filterSelect' value={this.state.certainty || "Certainty"} style={{marginRight: '0px'}} onChange={(val) => {
                  this.trackFilterUsage({ certainty: val.target.value })
                  this.setState({
                    certainty: val.target.value,
                    filteredData: this.props.rows.filter((item) => item.certainty?.indexOf(val.target.value) > -1 &&
                      item.clinical_focus?.indexOf(this.state.clinical_focus) > -1 &&
                      item.system?.indexOf(this.state.system) > -1 &&
                      item.accuracy?.indexOf(this.state.accuracy) > -1 &&
                      ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)

                    ),
                  })}}>
                  <option value="">Certainty</option>
                  {this.props.certainty_options.map(function(option){
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>

                <select className= 'form-control filterSelect' value={this.state.clinical_learning_calibration || "Clinical Learning Calibration"} style={{marginRight: '0px'}} onChange={(val) => {
                  this.trackFilterUsage({ clinical_learning_calibration: val.target.value })
                  this.setState({
                    clinical_learning_calibration: val.target.value,
                    filteredData: this.props.rows.filter((item) => item.clinical_learning_calibration?.indexOf(val.target.value) > -1 &&
                      item.clinical_focus?.indexOf(this.state.clinical_focus) > -1 &&
                      item.system?.indexOf(this.state.system) > -1 &&
                      item.accuracy?.indexOf(this.state.accuracy) > -1 &&
                      item.certainty?.indexOf(this.state.certainty) > -1 &&
                      ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                    ),
                  })}}>
                  <option value="">Clinical Learning Calibration</option>
                  {this.props.clinical_learning_calibration_options.map(function(option){
                    return (
                      <option key={option} value={option.charAt(0).toUpperCase() + option.slice(1)}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group searchField">
                <input type="text" name="search" placeholder='Search' className="form-control" onChange={(e) =>{
                  this.setState({
                    filteredData: this.props.rows.filter((item) => ((item.learning_objective || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (item.system || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (item.case_name || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (item.clinical_focus || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                      (item.teaching_point_url_name || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) &&
                      item.clinical_learning_calibration?.indexOf(this.state.clinical_learning_calibration) > -1 &&
                      item.clinical_focus?.indexOf(this.state.clinical_focus) > -1 &&
                      item.system?.indexOf(this.state.system) > -1 &&
                      item.accuracy?.indexOf(this.state.accuracy) > -1 &&
                      item.certainty?.indexOf(this.state.certainty) > -1),
                    search: e.target.value
                  });
                }} />
              </div>
            </div>
          </div>
          <div className='selectedFilters'>
            {this.state.accuracy?.length > 0 && <p onClick={() =>{this.setState({filteredData: this.props.rows.filter((item) =>  ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              ) && item.clinical_focus.indexOf(this.state.clinical_focus) > -1 && item.system.indexOf(this.state.system) > -1), accuracy: ''})}} className='filterSelectedVal'>{this.state.accuracy} X</p>}

            {this.state.certainty?.length > 0 && <p onClick={() =>{this.setState({filteredData: this.props.rows.filter((item) =>  ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              ) && item.clinical_focus.indexOf(this.state.clinical_focus) > -1 && item.system.indexOf(this.state.system) > -1), certainty: ''})}} className='filterSelectedVal'>{this.state.certainty} X</p>}

            {this.state.system?.length > 0 && <p onClick={() =>{this.setState({
              system: '',
              filteredData: this.props.rows.filter((item) =>  ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              ) && item.clinical_focus.indexOf(this.state.clinical_focus) > -1
            )})}} className='filterSelectedVal'>{this.state.system} X</p>}

            {this.state.clinical_learning_calibration?.length > 0 ? <p onClick={() =>{this.setState({filteredData: this.props.rows.filter((item) =>  ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              ) && item.clinical_focus.indexOf(this.state.clinical_focus) > -1 && item.system.indexOf(this.state.system) > -1), clinical_learning_calibration: ''})}} className='filterSelectedVal'>{this.state.clinical_learning_calibration} X</p> : null}

            {this.state.clinical_focus?.length > 0 && <p onClick={() =>{this.setState({
              clinical_focus: '',
              filteredData: this.props.rows.filter((item) =>  ((item.learning_objective || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.system || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.case_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.clinical_focus || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ||
                (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
              ) && item.system.indexOf(this.state.system) > -1
            )})}} className='filterSelectedVal'>{this.state.clinical_focus} X</p>}

          </div>

          <table className="detailsTable">
            <thead>
              <tr>
                <th className="bottomSectionGraphLegendText">CLINICAL LEARNING CALIBRATION</th>
                <th className="bottomSectionGraphLegendText">QUESTION CONTENT / LEARNING OBJECTIVE</th>
                <th className="bottomSectionGraphLegendText">ANSWER EXPLANATION / TEACHING POINT</th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => this.setState({
                    sortCol: 'clinical_focus',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('clinical_focus', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div className='sortable'>Clinical Focus <img src={this.sortImage('clinical_focus')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => this.setState({
                    sortCol: 'system',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('system', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div className='sortable'>System <img src={this.sortImage('system')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th className="bottomSectionGraphLegendText">Aquifer Case</th>
                <th className="bottomSectionGraphLegendText">Add To-Do</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredData.map((row) =>{
                return <tr>
                  <td>{row.clinical_learning_calibration ?? 'N/A'}</td>
                  <td>{row.learning_objective ?? 'N/A'}</td>
                  <td>
                    {this.getTeachingPointModal(row)}
                  </td>
                  <td><p className="clinicalFocusCell">{row.clinical_focus ?? 'N/A'}</p></td>
                  <td><p className="systemCell">{row.system ?? 'N/A'}</p></td>
                  <td>
                    <a className={'caseNameLink'} href = {row.case_path} target="_blank" onClick={ () => analytics.track("click_case_link", { page: window.location.href, source: 'Calibrate Reports', item_response_id: row.item_response_id }) }>{row.case_name}</a><br/><br/>
                    <div className={`progress-circle ${row.case_status.circle}`}>
                      {row.case_status.circle == 'yellow' ? `${row.case_progress}%` : null}
                      <span className="tooltip-box xsmall">
                        {row.case_status.progress_circle_tip}
                      </span>
                    </div>
                  </td>
                  <td>
                  <div dangerouslySetInnerHTML={{ __html: row.todo_action_element }} />
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default StudentResultsBonusDetailsTable;
